import { type FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { FormattedPrice } from 'dibs-buyer-price';
import { FreeShippingBadge, type BadgeBackgroundColor } from './FreeShippingBadge';

import dibsCss from 'dibs-css';

type ShippingInfoProps = {
    shippingPrices: Record<string, number> | null;
    currency: string;
    backgroundColor?: BadgeBackgroundColor;
    reduceMargins?: boolean;
};

export const ShippingInfo: FC<ShippingInfoProps> = ({
    currency,
    backgroundColor,
    reduceMargins,
    shippingPrices,
}) => {
    const amount = shippingPrices?.[currency] ?? null;

    return amount === 0 ? (
        <FreeShippingBadge backgroundColor={backgroundColor} reduceMargins={reduceMargins} />
    ) : (
        <div className={reduceMargins ? dibsCss.mb2px : dibsCss.mbXsmall} data-tn="shipping-cost">
            {amount === null ? (
                <FormattedMessage
                    id="dc.searchProductTile.shippingRatesVary"
                    defaultMessage="Shipping rates vary"
                />
            ) : (
                <FormattedMessage
                    id="dc.searchProductTile.shippingPrice"
                    defaultMessage="{price} shipping"
                    values={{ price: <FormattedPrice currency={currency} value={amount} /> }}
                />
            )}
        </div>
    );
};
